import React from "react"
import { Link } from "gatsby"
import {
    Section,
    Container,
    Title,
    Subtitle,
    Button,
    Icon,
    Column,
    Columns,
} from "bloomer"

import Layout from "../components/layout"
import SEO from "../components/seo"


const photoList = [{
    id: 0,
    src: '/gio.jpeg',
    caption: 'Google I/O 2018 - Participated in Google I/O held in Mountain View, California with a full sponsorship from Google as a valuable contributor to Google Developers ecosystem. '
}, {
    id: 1,
    src: '/allhands.jpg',
    caption: 'Mozilla All Hands, Orlando - One among the top 50 contributors from the world to get invited to partcipate in the Mozilla All Hands Orlando 2018. This was in recognition to my contributions to Mozilla SUMO & Firefox Reality projects. '
}, {
    id: 2,
    src: '/mozfest.png',
    caption: 'Mozilla Festival London - Selected by Mozilla to deliver a technical session on Decentralized Publishing at Mozilla Festival London 2018. It was an honour meeting Sir Tim Berners Lee during MozFest. '
}, {
    id: 3,
    src: '/oss.jpg',
    caption: 'Linux Foundation Diversity Scholar - One among the 56 community members around the globe to receive the Linux Foundation Scholarship for Open Source Summit North America. '
}]


const AboutPage = () => (
    <Layout>
        <SEO title="Learnings" />
        <Section className="has-background-white">
            <Container>
                <Columns >
                    <Column style={{ margin: '0 auto' }} isSize="3/4">
                        {/* Page title */}
                        <Title isSpaced>
                            Now that you have decided to read more :)
                        </Title>
                        <Subtitle>Here are some of my learnings</Subtitle>
                        <ul id="list-unstyled">
                        <li className="example">
                                <a href="https://dev.to/sethu/how-to-build-a-website-using-gatsby-airtable-in-30-mins-42gm" target="_blank" className="hover hover-1">
                                    How to build a website using Gatsby & Airtable in 30 mins
                                </a>
                            </li>
                            <br></br>
                            <li className="example">
                                <a href="https://medium.com/@sethusathyan/leap-in-my-career-7a5bbb0d7299" target="_blank" className="hover hover-1">
                                    Career transition from a Developer to a Program Manager
                                </a>
                            </li>
                            <br></br>
                            <li className="example">
                                <a href="https://medium.com/@sethusathyan/open-source-summit-north-america-2019-1a556baccf16" target="_blank" className="hover hover-1">
                                    Linux Foundation Scholarship & Open Source Summit North America
                                </a>
                            </li>
                            <br></br>
                            <li className="example">
                                <a href="https://medium.com/@sethusathyan/mozilla-festival-a-lifetime-experience-10bd9850c8cb" target="_blank" className="hover hover-1">
                                    Meeting Sir Tim Berners Lee & speaking at Mozilla Festival London
                                </a>
                            </li>
                            <br></br>
                            <li className="example">
                                <a href="https://twitter.com/GoogleDevsIN/status/1054235763733291008?s=20" target="_blank" className="hover hover-1">
                                    My experinece attending the first AMP Contributor Summit held in California
                                </a>
                            </li>
                            <br></br>
                            <li className="example">
                                <a href="https://medium.com/@sethusathyan/ghci2015-73ee28e962fc" target="_blank" className="hover hover-1">
                                    All about Grace Hopper Scholarship & getting my first interview call from Google for SRE role
                                </a>
                            </li>
                            <br></br>
                            <li className="example">
                                <a href="https://medium.com/@rohinimadhavp/getting-connected-with-sethu-sathyan-wit-learning-program-127111fbfbe6" target="_blank" className="hover hover-1">
                                    AMA on Grace Hopper Conference India & the scholarship                                </a>
                            </li>
                        </ul>
                        <Subtitle style={{ margin: '40px 0' }}>I am honured to be recognised for my work by different organisations. Thanks to everyone who supported me throughout.</Subtitle>
                        <Columns style={{ 'flex-wrap': 'wrap' }}>

                            {photoList.map(photo => (
                                <Column isSize="1/2" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '20px' }}>
                                    <img className="mx2 inline-block xs-mb2 lg-pb2 md-pb2 xs-pb1" style={{ width: "100%", objectFit: 'cover', maxHeight: '400px', objectPosition: 'center' }} src={photo.src} />
                                    <caption style={{ fontSize: '14px' }}>{photo.caption}</caption>
                                </Column>
                            ))}


                        </Columns>

                        <div >
                            <div style={{ justifyContent: 'space-between', marginBottom: '10px' }} className="logo-centre flex flex-row  flex-wrap max-width-4 mx-auto">
                            </div>
                            <div style={{ justifyContent: 'space-between', marginBottom: '10px' }} className="logo-centre flex flex-row  flex-wrap max-width-4 mx-auto">
                            </div>
                        </div>
                        {/* Back to homepage button */}
                        <Link to="/">
                            <Button isColor="primary" className="is-rounded" id="is-spaced">
                                <Icon className="fa fa-arrow-left fa-sm" />
                                <span>Go back</span>
                            </Button>
                        </Link>
                    </Column>
                </Columns>
            </Container>
        </Section>
    </Layout >
)

export default AboutPage
